import { InputRadio, InputSelect, InputText, debounce, usePromise } from "ui";
import { IStep, OrderSummary, useValidation } from "./IStep";
import * as styles from "./Steps.module.scss";
import { Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { MachineCartDelivery, MachineCartDeliveryMethod } from "ui/src/types";
import cn from 'classnames';

const loggedIn = window.app.preloadState.isLoggedIn;
const translations = window.app.preloadState.translation;
const machineCheckout = window.app.preloadState.machineSalesCheckout;

function DeliveryMethod({ cart, updateCart, proceed, goBack, enableNextStep, pickupBranches, deliveryAddresses }: IStep & { enableNextStep: (value: boolean) => void, pickupBranches: { label: string, value: string }[], deliveryAddresses: { label: string, value: string }[] }) {

    const fieldRequiredValidator = useValidation(translations["sharedTranslations.fieldIsRequired"]);

    const [delivery, setDelivery] = useState(cart.deliveryMethod);

    const [validationMessages, setValidationMessages] = useState<string[]>([]);

    useLayoutEffect(() => {
        setDelivery(cart.deliveryMethod);
    }, [cart.deliveryMethod]);

    const debouncedUpdate = useMemo(() => debounce(async (userInformation: MachineCartDelivery) => {
        await fetch(loggedIn
            ? `/api/machinesales/cart/delivery-method/update-authorized`
            : `/api/machinesales/cart/delivery-method/update-anonymous`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            },
            body: JSON.stringify(userInformation)
        });
        updateCart();
    }, 500), []);

    const setDeliveryMethod = (type: MachineCartDeliveryMethod) => {
        const newDelivery = {
            ...delivery,
            selectedDeliveryMethodType: type
        };
        setDelivery(newDelivery);
        debouncedUpdate(newDelivery);
    }

    const formHasAllRequiredFields = useMemo(() => !!(delivery.selectedDeliveryMethodType === MachineCartDeliveryMethod.Delivery
        ? (loggedIn
            ? (delivery.deliveryAddressId ? "_" : "")
            : delivery.firstName && delivery.lastName && delivery.streetAndNumber && delivery.town && delivery.zipCode && delivery.country)
        : (delivery.deliveryBranchId ? "_" : "")
    )?.length,
        [delivery.selectedDeliveryMethodType, delivery.deliveryAddressId, delivery.deliveryBranchId, delivery.firstName, delivery.lastName, delivery.streetAndNumber, delivery.town, delivery.zipCode, delivery.country]);

    const updateDeliveryInformation = useCallback((name: keyof MachineCartDelivery) => {
        return {
            value: delivery[name] ?? "",
            checked: delivery[name] ?? false,
            onChange: (ev: React.ChangeEvent<HTMLInputElement> | string | boolean) => {
                const newDelivery = {
                    ...delivery,
                    [name]: typeof ev === 'string' ? ev : typeof ev === 'boolean' ? ev : ev.target.value
                };
                setDelivery(newDelivery);
                debouncedUpdate(newDelivery);
            }
        } as any
    }, [delivery, debouncedUpdate]);

    useEffect(() => {
        const validated = delivery.selectedDeliveryMethodType === MachineCartDeliveryMethod.Delivery ?
            !fieldRequiredValidator.hasAnyErrors()
            : !!delivery.deliveryBranchId;
        enableNextStep?.(formHasAllRequiredFields && validated);
        },
        [delivery.selectedDeliveryMethodType, delivery.deliveryBranchId, formHasAllRequiredFields, fieldRequiredValidator.hasAnyErrors()]
    );

    const enabledToProceed = (!formHasAllRequiredFields || (delivery.selectedDeliveryMethodType === MachineCartDeliveryMethod.Delivery ?
        fieldRequiredValidator.hasAnyErrors()
        : !delivery.deliveryBranchId)) ? undefined : proceed;

    const validateDeliveryMethod = async () => {
        const result = await fetch(`/api/machinesales/cart/delivery-method/validate`, {
            method: 'GET',
            headers: {
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            }
        });
        const data: { message: string }[] = await result.json();
        setValidationMessages(data.map(x => x.message));
        return data.length == 0;
    };

    return (
        <div className={styles.wrapper}>
            <h1 className={styles.pageTitle}>{translations["machineSalesCheckout.deliveryMethod"]}</h1>
            <div className={styles.checkoutGrid}>
                <div className={cn(styles.checkoutGridMainArea, styles.thirdStepForm)}>
                    <div className={styles.inputCheckboxWithSufix}>
                        <InputRadio label={translations["machineSalesCheckout.delivery"]} disabled={false} name="deliveryMethod" checked={delivery.selectedDeliveryMethodType === MachineCartDeliveryMethod.Delivery} onChange={setDeliveryMethod.bind(0, MachineCartDeliveryMethod.Delivery)} />
                        {delivery.selectedDeliveryMethodType === MachineCartDeliveryMethod.Delivery && <span>{cart.summary.deliveryCost}</span>}
                    </div>
                    {delivery.selectedDeliveryMethodType === MachineCartDeliveryMethod.Delivery &&
                        <div className={cn(styles.methodGroup)}>
                            {!loggedIn ? <div className={cn(delivery.selectedDeliveryMethodType !== MachineCartDeliveryMethod.Delivery && styles.disabled)}>
                                <div className={styles.horizontalInputsGroup}>
                                    <InputText label={translations["machineSalesCheckout.firstName"]} name="firstName" {...updateDeliveryInformation("firstName")} {...fieldRequiredValidator('firstName')} />
                                    <InputText label={translations["machineSalesCheckout.lastName"]} name="lastName" {...updateDeliveryInformation("lastName")} {...fieldRequiredValidator('lastName')} />
                                </div>
                                <InputText label={translations["machineSalesCheckout.streetAndNumber"]} name="streetAndNumber" {...updateDeliveryInformation("streetAndNumber")} {...fieldRequiredValidator('streetAndNumber')} />
                                <div className={styles.horizontalInputsGroup}>
                                    <InputText label={translations["machineSalesCheckout.town"]} name="town" {...updateDeliveryInformation("town")} {...fieldRequiredValidator('town')} />
                                    <InputText label={translations["machineSalesCheckout.zipCode"]} name="zipCode" {...updateDeliveryInformation("zipCode")} {...fieldRequiredValidator('zipCode')} />
                                </div>
                                <InputSelect label={translations["machineSalesCheckout.country"]} name="country" {...fieldRequiredValidator('country')} options={[
                                    { label: 'Sweden', value: 'se' },
                                    { label: 'Germany', value: 'de' }
                                ]} {...updateDeliveryInformation("country")} />
                            </div> : <>
                                <InputSelect className={cn(delivery.selectedDeliveryMethodType !== MachineCartDeliveryMethod.Delivery && styles.disabled)} label={translations["machineSalesCheckout.deliveryAddress"]} name="deliveryAddress" options={deliveryAddresses} value={deliveryAddresses?.length ? deliveryAddresses[0].value : null} {...updateDeliveryInformation("deliveryAddressId")} {...fieldRequiredValidator('deliveryAddressId')} />
                                <a href={machineCheckout.addNewAddressMySweconLink} className={cn(styles.addAddressButton, "btn btn--outlined ", cart.addNewAddressMySweconLink && styles.buttonDisabled)}>+ {translations["machineSalesCheckout.addNewAddress"]}</a>
                            </>}
                        </div>}
                    <div className={styles.inputCheckboxWithSufix}>
                        <InputRadio label={translations["machineSalesCheckout.pickUpInStore"]} name="deliveryMethod" checked={delivery.selectedDeliveryMethodType === MachineCartDeliveryMethod.PickUp} onChange={setDeliveryMethod.bind(0, MachineCartDeliveryMethod.PickUp)} />
                        {delivery.selectedDeliveryMethodType === MachineCartDeliveryMethod.PickUp && <span>{translations["machineSalesCheckout.feeDeliveryLabel"]}</span>}
                    </div>
                    {delivery.selectedDeliveryMethodType === MachineCartDeliveryMethod.PickUp &&
                        <div className={cn(styles.methodGroup, delivery.selectedDeliveryMethodType !== MachineCartDeliveryMethod.PickUp && styles.disabled)}>
                            <InputSelect label={translations["machineSalesCheckout.branchAddress"]} name="branchAddress" options={pickupBranches} {...updateDeliveryInformation("deliveryBranchId")} {...fieldRequiredValidator('deliveryBranchId')} />
                        </div>}
                    <div className={styles.validationMessages}>
                        {validationMessages.map((message) => (
                            <div className={styles.validationMessage} key={message}>
                                {message}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.checkoutGridDetails}>
                    <OrderSummary cart={cart} step={3} goBack={goBack} proceed={enabledToProceed} validateCart={validateDeliveryMethod} />
                </div>
            </div>
        </div>
    );
}

export default DeliveryMethod;
